import { DocumentNode, gql } from "@apollo/client"

type apiType = "query" | "mutation"

export class ApiDef {
  type: apiType

  gql: DocumentNode

  authRequired: boolean

  constructor(type: apiType, def: string, authRequired = true) {
    this.type = type
    this.authRequired = authRequired
    this.gql = gql`
      ${def}
    `
  }
}
