export enum CheckoutSteps {
  Start = 0,
  Account = 1,
  Shipping = 2,
  Billing = 3,
  Payment = 4,
  Complete = 5,
}

type ProgressBarStepNames = "Account" | "Shipping" | "Payment" | "Complete"

export interface StepInterface {
  complete: boolean
  active: boolean
  name: ProgressBarStepNames
  icon: string
  nextText?: string
  skip?: boolean
  stepNumber?: number
  onClick?: Function
}

export const progressBarSteps: StepInterface[] = [
  {
    complete: false,
    active: true,
    name: "Account",
    icon: "shopping-cart",
    nextText: "Checkout Now",
    skip: false,
  },
  {
    complete: false,
    active: false,
    name: "Shipping",
    icon: "map-marker-alt",
    nextText: "Continue",
    skip: false,
  },
  {
    complete: false,
    active: false,
    name: "Payment",
    icon: "credit-card",
    skip: false,
  },
  {
    complete: false,
    active: false,
    name: "Complete",
    skip: false,
    icon: "box",
  },
]
