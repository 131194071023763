const {
  initializeAndTrackGoogleAnalytics,
  initializeAndTrackGoogleTagManager,
  initializeAndTrackFacebookPixel,
  initializeAndTrackTikTokPixel,
  initializeAndTrackHotjar,
  initializeChatwoot,
  initializeLinkedin,
  initializeAndTrackLuckyOrange,
} = require("./services")

const { isEnvironmentValid } = require("./helper")
const { trackFacebookPixel } = require("./services/facebook")

let isInitialized = false

exports.initializeAndTrack = (location, initialize = false, tagManagerInit) => {
  const options = window.gatsbyPluginGDPRCookiesOptions
  if (
    options &&
    options.waitForInitalization &&
    !initialize &&
    !isInitialized
  ) {
    if (isInitialized) {
      trackFacebookPixel(options.facebookPixel)
    }
    return
  }

  if (isEnvironmentValid(options.environments)) {
    if (location === undefined || location === null) {
      console.error(
        "Please provide a reach router location to the initializeAndTrack function."
      )
    } else {
      initializeAndTrackGoogleTagManager(options.googleTagManager, location)
      initializeAndTrackGoogleAnalytics(
        options.googleAnalytics,
        location,
        tagManagerInit
      )

      setTimeout(() => {
        initializeAndTrackFacebookPixel(options.facebookPixel)
      }, 500)
      setTimeout(() => {
        initializeAndTrackTikTokPixel(options.tikTokPixel)
        initializeAndTrackLuckyOrange(options.luckyOrange)
        initializeAndTrackHotjar(options.hotjar)
        initializeChatwoot(options.chatwoot)
        initializeLinkedin(options.linkedin)
        isInitialized = true
      }, 700)
    }
  }
}
