const { getCookie, validLuckyOrangeId } = require("../helper")

exports.addLuckyOrange = (options) => {
  return new Promise((resolve, reject) => {
    if (window.gatsbyPluginGDPRCookiesLuckyOrangeAdded) return resolve(true)

    if (!options.trackingId) {
      return resolve(true)
    }

    var s = document.getElementsByTagName("head")[0]
    var b = document.createElement("script")
    b.type = "text/javascript"
    b.async = true
    b.defer = true
    b.src = `https://tools.luckyorange.com/core/lo.js?site-id=${options.trackingId}`
    s.appendChild(b)
    window.gatsbyPluginGDPRCookiesLuckyOrangeAdded = true

    resolve(true)
  })
}

exports.initializeLuckyOrange = (options) => {
  if (
    !window.gatsbyPluginGDPRCookiesLuckyOrangeAdded &&
    getCookie(options.cookieName) === `true` &&
    validLuckyOrangeId(options) &&
    window.LO
  ) {
    window.__lo_disable_page_detection = true

    window.gatsbyPluginGDPRCookiesLuckyOrangeInitialized = true
  }
}

exports.trackLuckyOrange = (options) => {
  if (!window.gatsbyPluginGDPRCookiesLuckyOrangeInitialized) {
    return
  }

  try {
    if (window.LO) {
      window.LO.new_page()
    }
  } catch (ex) {}
}
