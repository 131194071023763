import { CartItem } from "../types/checkoutTypes"

export const getUpdatedCartItems = (
  newItemId: string,
  newItemQuantity: number,
  cartItems: CartItem[]
): CartItem[] => {
  const existingItem = cartItems.find(item => item.productId === newItemId)

  if (existingItem) {
    return cartItems
  } else {
    return [...cartItems, { productId: newItemId, quantity: newItemQuantity }]
  }
}
