export const defaultOptions = {
  environments: [`production`],
  waitForInitalization: true,
  googleAnalytics: {
    cookieName: `gatsby-gdpr-google-analytics`,
    anonymize: true,
    allowAdFeatures: false,
  },
  googleTagManager: {
    cookieName: `gatsby-gdpr-google-tagmanager`,
    dataLayerName: `dataLayer`,
    routeChangeEvent: `gatsbyRouteChange`,
  },
  luckyOrange: {
    cookieName: `gatsby-gdpr-facebook-pixel`,
  },
  facebookPixel: {
    cookieName: `gatsby-gdpr-facebook-pixel`,
  },
  tikTokPixel: {
    cookieName: `gatsby-gdpr-tiktok-pixel`,
  },
  hotjar: {
    cookieName: `gatsby-gdpr-hotjar`,
  },
  chatwoot: {
    cookieName: "gatsby-gdpr-chatwoot",
  },
  linkedin: {
    cookieName: "gatsby-gdpr-linkedin",
  },
}
