import React, { useEffect, useReducer, createContext, useContext } from "react"
import { getLs } from "../utils/localStorage"
import { navigate } from "gatsby"
import API from "../server/serverInterface"

interface QueryParams {
  affiliateId: string | null | undefined
  affiliateExpiry: string | null | undefined
  campaignId: string | null | undefined
}

export interface AuthStateType {
  token: string | null | undefined
  exp: string | null | undefined
  firstName: string | null | undefined
  lastName: string | null | undefined
  email: string | null | undefined
  membershipLevel: string | null | undefined

  customerAccount: string | null | undefined
  isLoggedIn: Function
  affiliateId: string | null | undefined
  affiliateExpiry: string | null | undefined
  campaignId: string | null | undefined
  updated?: string | null | undefined
  localStorageLoaded: boolean
}

interface Payload {
  token?: string
  exp?: string
  firstName?: string
  lastName?: string
  email?: string
  membershipLevel?: string

  customerAccount?: string
  updated?: string
  localStorageLoaded?: boolean
}

type AuthActionType =
  | { type: "LOGIN"; payload: Payload; redirectUrl?: string }
  | { type: "LOCAL_STORAGE_LOADED"; localStorageLoaded?: boolean }
  | { type: "LOGOUT"; redirectUrl?: string }
  | { type: "SET_QUERY_PARAMS"; payload: QueryParams }

type Dispatch = (action: AuthActionType, payload?: Payload) => void
type AuthProviderProps = { children: React.ReactNode }

const AuthStateContext = createContext<State | undefined>(undefined)
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined)

const initialState: AuthStateType = {
  token: null,
  exp: null,
  firstName: null,
  lastName: null,
  email: null,
  membershipLevel: null,

  customerAccount: null,
  isLoggedIn: () => false,
  affiliateId: null,
  affiliateExpiry: null,
  campaignId: null,
  localStorageLoaded: false,
  updated: null,
}

const reducer = (
  state: AuthStateType,
  action: AuthActionType
): AuthStateType => {
  switch (action.type) {
    case "LOGIN":
      const {
        token,
        exp,
        firstName,
        lastName,
        email,
        membershipLevel,
        customerAccount,

        localStorageLoaded,
        updated,
      } = action.payload
      localStorage.setItem("userData", JSON.stringify(action.payload))
      setTimeout(() => {
        if (action.redirectUrl) {
          navigate(action.redirectUrl)
        }
      }, 700)

      return {
        ...state,
        token,
        exp,
        firstName,
        lastName,
        email,
        membershipLevel,

        customerAccount,
        updated,
        localStorageLoaded:
          state.localStorageLoaded ||
          (localStorageLoaded ? localStorageLoaded : false),
      }
    case "LOCAL_STORAGE_LOADED":
      return {
        ...state,
        localStorageLoaded:
          state.localStorageLoaded ||
          (action.localStorageLoaded ? action.localStorageLoaded : false),
      }
    case "LOGOUT":
      setTimeout(() => {
        if (action.redirectUrl) {
          navigate(action.redirectUrl)
        }
      }, 300)

      localStorage.removeItem("userData")
      API.getClientDef("account")?.client.clearStore()

      return {
        ...state,
        token: null,
        exp: null,
        firstName: null,
        lastName: null,
        email: null,
        membershipLevel: null,

        customerAccount: null,
      }
    case "SET_QUERY_PARAMS":
      return {
        ...state,
        affiliateId: action.payload.affiliateId,
        affiliateExpiry: action.payload.affiliateExpiry,
        campaignId: action.payload.campaignId,
      }
    default:
      throw new Error("Bad action ")
  }
}

let storedUserData: Payload

const checkSetUserData = () => {
  if (!getLs("userData")) return false

  storedUserData = getLs("userData")

  const expiryDate = Date.parse(storedUserData && storedUserData.exp)
  const currentDate = Date.now()
  const isExpired = expiryDate < currentDate || storedUserData == null

  return !isExpired
}

const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (checkSetUserData()) {
        // Token exists and isn't expired, log in
        if (!state.localStorageLoaded) {
          dispatch({
            type: "LOGIN",
            payload: { ...storedUserData, localStorageLoaded: true },
          })
        }
      } else {
        localStorage.removeItem("userData")
        dispatch({
          type: "LOCAL_STORAGE_LOADED",
          localStorageLoaded: true,
        })
      }
    }
  }, [])
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

const useAuthState = (): AuthStateType => {
  const context = useContext(AuthStateContext)

  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider")
  }

  context.isLoggedIn = () => {
    if (!context.token) return false

    const expiryDate = Date.parse(context.exp)
    const currentDate = Date.now()
    const isExpired = expiryDate < currentDate || context.exp == null

    return !isExpired && context.localStorageLoaded
  }

  return context
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider")
  }
  return context
}

export { AuthContextProvider, useAuthState, useAuthDispatch }
