export type Currency = "cad" | "usd"

export type CartItem = { productId: string; quantity: number }

export interface StepInterface {
  stepNumber: number
  progressBarNumber: number
  nextText?: string
}

export interface ProgressBarItem {
  number: number
  name: "Account" | "Shipping" | "Payment" | "Complete"
  icon: string
  relatedCheckoutSteps: number[]
}

export interface State {
  cartItems: CartItem[]
  currentStep: number
  currency: Currency
  shipping: number | undefined
  taxes: number | undefined
  couponDiscount: number
  couponDiscountDays: number
  couponCode: string
  couponDescription: string
  accountRequired: boolean
  shippingNotes: string
  country: string
  countryInventory: string
  isGift: boolean
  total: number
  subTotal: number
  isChecoutInitiated: boolean
}

export enum CheckoutActionKind {
  SET_CURRENCY = "SET_CURRENCY",
  ADD_TO_CART = "ADD_TO_CART",
  REMOVE_FROM_CART = "REMOVE_FROM_CART",
  INCREMENT_CART_ITEM = "INCREMENT_CART_ITEM",
  DECREMENT_CART_ITEM = "DECREMENT_CART_ITEM",
  SET_SUBTOTAL = "SET_SUBTOTAL",
  SET_TOTAL = "SET_TOTAL",
  GO_TO_STEP = "GO_TO_STEP",
  SET_SHIPPING = "SET_SHIPPING",
  SET_TAXES = "SET_TAXES",
  SET_COUPON_DISCOUNT = "SET_COUPON_DISCOUNT",
  SET_COUPON_DISCOUNT_DAYS = "SET_COUPON_DISCOUNT_DAYS",
  SET_COUPON_CODE = "SET_COUPON_CODE",
  SET_COUPON_DESCRIPTION = "SET_COUPON_DESCRIPTION",
  SET_SHIPPING_NOTES = "SET_SHIPPING_NOTES",
  CLEAR_CART = "CLEAR_CART",
  CLEAR_COUPONS = "CLEAR_COUPONS",
  SET_COUNTRY = "SET_COUNTRY",
  SET_COUNTRY_INVENTORY = "SET_COUNTRY_INVENTORY",
  TOGGLE_GIFT_ORDER = "TOGGLE_GIFT_ORDER",
}

export type Action =
  | AddToCartAction
  | IncrementCartItemAction
  | DecrementCartItemAction
  | GoToStepAction
  | SetCurrencyAction
  | RemoveFromCartAction
  | SetSubtotalAction
  | SetTotalAction
  | SetShippingAction
  | SetTaxesAction
  | SetCouponDiscountAction
  | SetCouponDiscountDaysAction
  | SetShippingNotesAction
  | ClearCartAction
  | ClearCouponsAction
  | SetCountryAction
  | SetCountryInventoryAction
  | SetCouponCodeAction
  | SetCouponDescriptionAction
  | ToggleGiftAction

interface SetCurrencyAction {
  type: CheckoutActionKind.SET_CURRENCY
  payload: {
    currency: Currency
  }
}

interface ToggleGiftAction {
  type: CheckoutActionKind.TOGGLE_GIFT_ORDER
}

export interface AddToCartAction {
  type: CheckoutActionKind.ADD_TO_CART
  payload: {
    id: string
    quantity: number
  }
}

export interface RemoveFromCartAction {
  type: CheckoutActionKind.REMOVE_FROM_CART
  payload: {
    cartItemId: string
  }
}

export interface IncrementCartItemAction {
  type: CheckoutActionKind.INCREMENT_CART_ITEM
  payload: {
    cartItemId: string
  }
}

export interface DecrementCartItemAction {
  type: CheckoutActionKind.DECREMENT_CART_ITEM
  payload: {
    cartItemId: string
  }
}

export interface SetSubtotalAction {
  type: CheckoutActionKind.SET_SUBTOTAL
  payload: {
    amount: number
  }
}

export interface SetTotalAction {
  type: CheckoutActionKind.SET_TOTAL
  payload: {
    amount: number
  }
}

export interface SetShippingAction {
  type: CheckoutActionKind.SET_SHIPPING
  payload: {
    amount: number
  }
}

export interface SetTaxesAction {
  type: CheckoutActionKind.SET_TAXES
  payload: {
    amount: number
  }
}

export interface SetCouponDiscountAction {
  type: CheckoutActionKind.SET_COUPON_DISCOUNT
  payload: {
    amount: number
  }
}

export interface SetCouponDiscountDaysAction {
  type: CheckoutActionKind.SET_COUPON_DISCOUNT_DAYS
  payload: {
    days: number
  }
}

export interface SetCouponCodeAction {
  type: CheckoutActionKind.SET_COUPON_CODE
  payload: {
    code: string
  }
}

export interface SetCouponDescriptionAction {
  type: CheckoutActionKind.SET_COUPON_DESCRIPTION
  payload: {
    description: string
  }
}

interface GoToStepAction {
  type: CheckoutActionKind.GO_TO_STEP
  payload: {
    stepNumber: number
  }
}

interface SetShippingNotesAction {
  type: CheckoutActionKind.SET_SHIPPING_NOTES
  payload: {
    notes: string
  }
}

interface ClearCartAction {
  type: CheckoutActionKind.CLEAR_CART
}

interface ClearCouponsAction {
  type: CheckoutActionKind.CLEAR_COUPONS
}

interface SetCountryAction {
  type: CheckoutActionKind.SET_COUNTRY
  payload: {
    country: string
  }
}

interface SetCountryInventoryAction {
  type: CheckoutActionKind.SET_COUNTRY_INVENTORY
  payload: {
    country: string
  }
}
