const getLs = (item: string): string | object | null => {
  if (typeof window !== `undefined`) {
    const itemJson = localStorage.getItem(item)

    try {
      return itemJson ? JSON.parse(itemJson) : null
    } catch (error) {
      return itemJson
    }
  }
  return null
}

const setLs = (itemName: string, item: any) => {
  if (typeof window !== `undefined`) {
    localStorage.setItem(itemName, JSON.stringify(item))
  }
}

const removeLs = (itemName: string) => {
  if (typeof window !== `undefined`) {
    localStorage.removeItem(itemName)
  }
}

export { getLs, setLs, removeLs }
