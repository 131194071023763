import { PurchaseItem } from "../../ts/interfaces"
import { getCartProduct } from "../../utils/stripejs"
import { CartProduct } from "../../ts/interfaces"
import { Currency, CartItem } from "../types/checkoutTypes"

export const cartItemsParseByType = (
  cartItems: CartItem[],
  currency: Currency
): PurchaseItem[][] => {
  return (cartItems
    .map(item => getCartProduct(item.productId))
    .filter(x => x) as CartProduct[]).reduce(
    (acc: PurchaseItem[][], cur: CartProduct) => {
      return cur.prices[currency]!.type === "recurring"
        ? [
            acc[0],
            [
              ...acc[1],
              {
                priceId: cur.prices[currency]?.id,
                // quantity: cartItems[cur.id],
                quantity: cartItems.find(item => item.productId === cur.id)
                  ?.quantity,
              } as PurchaseItem,
            ],
          ]
        : [
            [
              ...acc[0],
              {
                priceId: cur.prices[currency]?.id,
                quantity: cartItems.find(item => item.productId === cur.id)
                  ?.quantity,
              } as PurchaseItem,
            ],
            acc[1],
          ]
    },
    ([[], []] as unknown) as PurchaseItem[][]
  )
}

export const getCartOneTimeItems = (
  cartItems: CartItem[],
  currency: Currency
): PurchaseItem[] => {
  return cartItemsParseByType(cartItems, currency)[0]
}

export const getCartSubscriptionItems = (
  cartItems: CartItem[],
  currency: Currency
): PurchaseItem[] => {
  return cartItemsParseByType(cartItems, currency)[1]
}
