import { CartItem, Currency } from "../types/checkoutTypes"
import { getCartProduct } from "../../utils/stripejs"
import { CartProduct } from "../../ts/interfaces"
import { Product } from "../product"

export const calcCartSubtotal = (
  cartItems: CartItem[],
  currency: Currency,
  couponDiscountDays: number
): number => {
  return getCartItemsForTotals(cartItems, couponDiscountDays)
    .map(item => {
      const product = getCartProduct(item.productId)

      if (!product || !product.prices || !product.prices[currency]) {
        return 0
      } else {
        return product.prices[currency]!.unit_amount * item.quantity
      }
    })
    .reduce((acc, price) => acc + price, 0)
}

export const calcCartTotal = (
  cartItems: CartItem[],
  currency: Currency,
  couponDiscountDays: number,
  taxes: number = 0,
  shipping: number = 0,
  couponDiscount: number = 0
): number => {
  return (
    getCartItemsForTotals(cartItems, couponDiscountDays)
      .map(item => {
        const product = getCartProduct(item.productId)

        if (!product || !product.prices || !product.prices[currency]) {
          return 0
        } else {
          return product.prices[currency]!.unit_amount * item.quantity
        }
      })
      .reduce((acc, price) => acc + price, 0) +
    taxes +
    shipping -
    couponDiscount
  )
}

export const isTaxableItemInCart = (cartItems: CartItem[]): boolean => {
  return cartItems
    .map(item => {
      const product = getCartProduct(item.productId)
      return product &&
        product.metadata.avataxCode &&
        product.metadata.avataxCode !== "false"
        ? true
        : false
    })
    .includes(true)
}

export const isShippableItemInCart = (cartItems: CartItem[]): boolean => {
  return cartItems
    .map(item => {
      const product = getCartProduct(item.productId)

      return product !== null && product.metadata.shippingRequired === "true"
    })
    .includes(true)
}

export const isHeadsetInCart = (cartItems: CartItem[]): boolean => {
  return cartItems.some(item => isHeadset(getCartProduct(item.productId)))
}
export const isHeadset = (product: CartProduct | null): boolean => {
  return (
    product !== null &&
    (product.metadata.bladeSKU === "SEN_HEADSET_V1" ||
      product.metadata.bladeSKU === "SEN_HEADSET_V1.5" ||
      product.metadata.bladeSKU === "SEN_HEADSET_OPENV1" ||
      product.metadata.bladeSKU === "SEN_HEADSET_REFURBV1")
  )
}

export const getShippableCartItemIds = (cartItems: CartItem[]): string[] => {
  const ids = []

  for (const item of cartItems) {
    const product = getCartProduct(item.productId)
    if (product !== null && product.metadata.shippingRequired === "true") {
      ids.push(item.productId)
    }
  }
  return ids
}

export const isSubscriptionInCart = (cartItems: CartItem[]): boolean => {
  return cartItems
    .map(item => {
      const product = getCartProduct(item.productId)

      return (
        product !== null &&
        product !== undefined &&
        (product.prices.cad?.type === "recurring" ||
          product.prices.usd?.type === "recurring")
      )
    })
    .includes(true)
}

export const isAccountRequiredItemInCart = (cartItems: CartItem[]): boolean => {
  return cartItems
    .map(item => {
      const product = getCartProduct(item.productId)

      return product !== null && product.metadata?.accountRequired === "true"
    })
    .includes(true)
}

export const calcNumItemsInCart = (cartItems: CartItem[]): number => {
  return cartItems
    .map(item => item.quantity)
    .reduce((acc, quantity) => acc + quantity, 0)
}

export const doesPurchaseRequireAccount = (cartItems: CartItem[]): boolean =>
  isSubscriptionInCart(cartItems) || isAccountRequiredItemInCart(cartItems)

// TODO: Confirm headset not just "shippable item"
export const isHeadsetAndMembershipInCart = (cartItems: CartItem[]) =>
  isSubscriptionInCart(cartItems) && isHeadsetInCart(cartItems)

const getSubscriptionTrialDays = (product: CartProduct): number => {
  if (!product?.metadata?.trial_days) return 0
  return parseInt(product.metadata.trial_days)
}

export const getSubscriptionInCartTrialDays = (
  cartItems: CartItem[]
): number => {
  if (getNonShippableItemsInCart(cartItems).length === 0) return 0

  const item = getNonShippableItemsInCart(cartItems)[0]
  const product = getCartProduct(item.productId)

  if (!product) return 0

  return getSubscriptionTrialDays(product)
}

export const isHeadsetSubscriptionPromoApplicable = (cartItems: CartItem[]) => {
  return (
    isHeadsetAndMembershipInCart(cartItems) &&
    isSubscriptionWithTrialInCart(cartItems)
  )
}

const getNonShippableItemsInCart = (cartItems: CartItem[]): CartItem[] => {
  return cartItems.filter(item => {
    const product = getCartProduct(item.productId)

    return product !== null && product.metadata.shippingRequired !== "true"
  })
}

export const getShippableItemsInCart = (cartItems: CartItem[]): CartItem[] => {
  return cartItems.filter(item => {
    const product = getCartProduct(item.productId)

    return product !== null && product.metadata.shippingRequired === "true"
  })
}

const isSubscriptionWithTrialInCart = (cartItems: CartItem[]) =>
  cartItems
    .map(item => {
      const product = getCartProduct(item.productId)

      return (
        product !== null &&
        (product.prices.cad?.type === "recurring" ||
          product.prices.usd?.type === "recurring") &&
        getSubscriptionTrialDays(product) > 0
      )
    })
    .includes(true)

export const getCartItemsForTotals = (
  cartItems: CartItem[],
  couponDiscountDays: number
) => {
  if (
    isHeadsetSubscriptionPromoApplicable(cartItems) ||
    couponDiscountDays > 0
  ) {
    return getShippableItemsInCart(cartItems)
  }
  return cartItems
}

export const getOutofRegionProducts = (
  country: string,
  cartItems: CartItem[],
  products: Product[]
): Product[] => {
  const outOfRegionP: Product[] = []
  cartItems.forEach(ci => {
    const p = products.find(sp => {
      return sp.isOutOfRegion(country) && ci.productId === sp.actualstripeId
    })
    if (p) {
      outOfRegionP.push(p)
    }
  })
  return outOfRegionP
}
